import { render, staticRenderFns } from "./totalOrder.vue?vue&type=template&id=76099846&scoped=true&"
import script from "./totalOrder.vue?vue&type=script&lang=js&"
export * from "./totalOrder.vue?vue&type=script&lang=js&"
import style0 from "./totalOrder.vue?vue&type=style&index=0&id=76099846&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76099846",
  null
  
)

export default component.exports